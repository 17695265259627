<template>
    <header class="page-title">
        <h1><i class="bi bi-bar-chart-fill"></i> レポート - 指標推移</h1>
    </header>

    <section class="section">
        <div class="row align-items-end">
            <div class="col-6">
                <label class="form-label">事業部</label>
                <form-select
                    v-model="condition.department_id"
                    :options="options_department"
                ></form-select>
            </div>
            <div class="col-12">
                <label class="form-label">集計期間</label>
                <div class="input-group">
                    <form-input-date
                        v-model="condition.date_from"
                    ></form-input-date>
                    <span class="input-group-text">〜</span>
                    <form-input-date
                        v-model="condition.date_to"
                    ></form-input-date>
                </div>
            </div>
            <div class="col-6">
                <button @click="search()" :disabled="loading_count > 0" class="btn btn-primary">表示</button>
            </div>
        </div>
    </section>

    <section class="section">
        <inline-loader v-if="loading_count > 0"></inline-loader>
        <div v-else class="table-responsive" style="width: 100%;">
            <template v-if="indicators.length > 0">
                <table class="table table-bordered">
                    <thead>
                        <tr class="table-dark">
                            <th class="text-center col-6">基準日</th>
                            <th class="text-center col-5">当週見込み案件数</th>
                            <th class="text-center col-5">前週再訪問アポ数</th>
                            <th class="text-center col-5">当週着地予想</th>
                            <th class="text-center col-5">当月着地予想</th>
                            <th class="text-center col-5">前週受注数</th>
                            <th class="text-center col-5">前週他者落ち数</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="indicator of indicators" :key="indicator.indicator_date">
                            <td class="text-center">{{ indicator.indicator_date }}</td>
                            <td class="text-end"><button type="button" class="btn btn-link p-0" data-bs-toggle="modal" data-bs-target="#projectListModal" @click="openProjectListModal(indicator, 'prospect_weekly')">{{ indicator.prospect_weekly }}</button></td>
                            <td class="text-end"><button type="button" class="btn btn-link p-0" data-bs-toggle="modal" data-bs-target="#projectListModal" @click="openProjectListModal(indicator, 'revisit_appointment_weekly')">{{ indicator.revisit_appointment_weekly }}</button></td>
                            <td class="text-end"><button type="button" class="btn btn-link p-0" data-bs-toggle="modal" data-bs-target="#projectListModal" @click="openProjectListModal(indicator, 'forecast_weekly')">{{ indicator.forecast_weekly }}</button></td>
                            <td class="text-end"><button type="button" class="btn btn-link p-0" data-bs-toggle="modal" data-bs-target="#projectListModal" @click="openProjectListModal(indicator, 'forecast_monthly')">{{ indicator.forecast_monthly }}</button></td>
                            <td class="text-end"><button type="button" class="btn btn-link p-0" data-bs-toggle="modal" data-bs-target="#projectListModal" @click="openProjectListModal(indicator, 'ordered_weekly')">{{ indicator.ordered_weekly }}</button></td>
                            <td class="text-end"><button type="button" class="btn btn-link p-0" data-bs-toggle="modal" data-bs-target="#projectListModal" @click="openProjectListModal(indicator, 'lost_weekly')">{{ indicator.lost_weekly }}</button></td>
                        </tr>
                    </tbody>
                </table>
            </template>
            <template v-else>
                <p class="alert alert-secondary">データがありません</p>
            </template>
        </div>
    </section>

    <!-- 各数値クリック時OPENモーダル -->
    <!--
        bootstrapのモーダル機能を利用している
        showクラスが追加されると開く
        class="modal fade show"
    -->
    <div class="modal fade" id="projectListModal" tabindex="-1" aria-labelledby="projectListModal" aria-hidden="true">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-header">
                    <h3 class="modal-title" :id="`activityModalLabel`">案件一覧 - {{ modal_title }}</h3>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body mb-5">
                    <div v-if="modal_projects_list.length">
                        <table class="table table-sm">
                            <thead>
                                <tr class="table-dark table-head">
                                    <th></th>
                                    <th class="text-center w-ms">会社名</th>
                                    <th class="text-center w-ss">客先担当者</th>
                                    <th class="text-center w-ss">追客担当</th>
                                    <th class="text-center w-ss">ステータス</th>
                                    <th class="text-center w-ss">評価</th>
                                    <th class="text-center w-mm">規格</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="project in modal_projects_list" :key="project.project_id">
                                    <td>
                                        <router-link :to="{name: 'ProjectEdit', params: {id: project.project_id}}" class="btn btn-link" target="_blank"><i class="bi bi-box-arrow-up-right"></i></router-link>
                                    </td>
                                    <td>{{ project.customer.company_name }}</td>
                                    <td>{{ project.primary_contact ? project.primary_contact.contact_name : '' }}</td>
                                    <td>{{ project.follow_user ? project.follow_user.user_name : '' }}</td>
                                    <td>{{ project.status_label }}</td>
                                    <td>{{ project.rank_label }}</td>
                                    <td>{{ project.standards_label_all }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div v-else>
                        <p class="alert alert-secondary">{{ modal_loading ? '検索中です' : '※案件が存在しません' }}</p>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-outline-secondary mb-0" data-bs-dismiss="modal">閉じる</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import FormSelect from '@/components/forms/FormSelect';
import FormInputDate from '@/components/forms/FormInputDate';
import InlineLoader from '@/components/tools/InlineLoader.vue';
import Department from '@/models/entities/department';
import Project from '@/models/entities/project';

export default {
    name: 'Indicator',
    components: {
        InlineLoader,
        FormInputDate,
        FormSelect,
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showErrorMessage',
        'showMessage',
    ],
    data() {
        return {
            // ローディング
            loading_count: 0,

            // 検索フォーム
            options_department: [],
            exists_options_department: false,

            // 検索条件
            condition: {
                department_id: null,
                date_from: null,
                date_to: null,
            },

            // 表示データ
            indicators: [],

            // モーダル
            modal_loading: false,
            modal_title: null,
            modal_projects_list: [],
        }
    },
    mounted() {
        this.fetchDepartments();
        this.initConditionDate();
    },
    methods: {
        fetchDepartments() {
            this.loading_count++;

            // 事業部マスタ取得
            this.$http.get('/master/department')
            .then(response => {
                for (let row of response.data) {
                    let department = new Department(row);
                    this.options_department.push({value: department.department_id, label: department.department_name});
                }

                // 事業部初期値セット
                if (this.$store.state.auth.department_id) {
                    this.condition.department_id = this.$store.state.auth.department_id;
                } else {
                    this.condition.department_id = this.options_department[0].value;
                }

                this.search();
            })
            .finally(() => {
                //ローディング終了
                this.loading_count--;
            })
        },
        // 検索
        search() {
            if (
                this.$helper.isBlank(this.condition.date_from)
                || this.$helper.isBlank(this.condition.date_to)
            ) {
                this.showErrorMessage('集計期間を設定してください');
                return true;
            }

            // ローディング開始
            this.loading_count++;
            // 表示データ初期化
            this.indicators.splice(0);

            this.$http.get('/report/indicator', {
                params: this.condition,
            })
            .then(response => {
                this.indicators = response.data;
            })
            .finally(() => {
                // ローディング終了
                this.loading_count--;
            })
        },
        thisMonday(date) {
            const this_year = date.getFullYear();
            const this_month = date.getMonth();
            const dates = date.getDate();
            const day_num = date.getDay();
            const this_monday = dates - day_num + 1;
            return new Date(this_year, this_month, this_monday);
        },
        initConditionDate() {
            let today = new Date();
            let first_of_month = new Date(today.getFullYear(), today.getMonth(), 1);
            let last_of_month = new Date(today.getFullYear(), today.getMonth() + 1, 0);

            this.condition.date_from = this.$helper.ymdForInputDate(first_of_month);
            this.condition.date_to = this.$helper.ymdForInputDate(last_of_month);
        },
        //指定数値の案件一覧モーダルOPEN
        openProjectListModal(indicator, type) {
            if (indicator[type] === 0) {
                this.modal_projects_list.splice(0);
                return;
            }

            this.modal_loading = true;

            // モーダルの案件一覧をリセット
            this.modal_projects_list.splice(0);

            // モーダルタイトル生成
            if (type === "prospect_weekly") {this.modal_title = '当週見込み案件';}
            else if (type === "revisit_appointment_weekly") {this.modal_title = '前週再訪問アポ';}
            else if (type === "forecast_weekly") {this.modal_title = '当週着地予想';}
            else if (type === "forecast_monthly") {this.modal_title = '当月着地予想';}
            else if (type === "ordered_weekly") {this.modal_title = '前週受注';}
            else if (type === "lost_weekly") {this.modal_title = '前週他者落ち';}

            //案件一覧取得
            this.$http.get('/project/ids', {
                params: {
                    project_ids: indicator[type + '_ids'],
                },
            })
            .then((response) => {
                for (let row of response.data) {
                    this.modal_projects_list.push(new Project(row));
                }
            })
            .finally(() => {
                this.modal_loading = false;
            })
        },
    },
}
</script>
