<template>
    <input
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
        :required="required"
        :disabled="disabled"
        :placeholder="placeholder"
        type="date"
        max="9999-12-31"
        class="form-control"
        :class="{[optional_class]: true}"
    >
</template>

<script>
export default {
    name: 'FormInputDate',
    props: {
        modelValue: {
            type: String
        },
        required: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        placeholder: {
            type: String,
            default: ""
        },
        optional_class: {
            type: String,
            default: ''
        },
    },
    emits: [
        'update:modelValue'
    ],
}
// $(function() {
//     $('#datepicker').datepicker();
// });
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .input-group-append {
        cursor: pointer;
    }
</style>
